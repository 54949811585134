@font-face {
  font-family: "OlympicSans-Regular";
  src: url("./assets/fonts/OlympicSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/OlympicSans-Regular.woff") format("woff");
}

cast-media-player {
  --font-family: "OlympicSans-Regular";
  --logo-image: url("./assets/img/logo-white.svg");
  --splash-image: url("./assets/img/ocs-readytocast.png");
  --splash-size: 100vw auto, auto;
  --pause-image: url("./assets/img/pause.svg");
  --playback-logo-image: url("./assets/img/logo-white.svg")
}

/* FOR AD OVERLAY */
#adContainer {
  width: 100%;
  height: 100%;
}
